body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1a1a;
  text-align: center;
  margin: 4% 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div,
h1,
h2 {
  font-family: var(--font);
  color: white;
}

.App {
  max-width: 1200px;
  margin: 4% auto;
  padding: 0 4%;
}

h1 {
  font-size: 3em;
  margin-bottom: 0;
}

h2 {
}

a {
  color: #fefffe;
  text-decoration: none;
}

.languages {
  opacity: 0.5;
  text-transform: uppercase;
  font-family: sans-serif;
  margin: 4% auto;
  line-height: 1.8em;
  display: block;
  font-size: 0.8em;
  max-width: 1000px;
}

.logo {
  max-width: 160px;
}

.store-links {
  margin: 2% 0;
}

.store-img {
  height: 70px;
  margin: 2% 1%;
}

.footer {
  margin: 4% 0;
  text-align: center;
}
.footer a {
  opacity: 0.6;
  font-size: 0.8em;
  margin: 0 2%;
}
.footer > a:hover {
  opacity: 1;
}

.foot-note {
  opacity: 0.4;
  max-width: 800px;
  font-size: 0.8em;
  margin: 0 auto;
}

.previews {
  margin: 8% 0;
  width: 100%;
  min-height: 800px;
  background-image: url(../images/previews.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 760px) {
  h1 {
    font-size: 2em;
  }

  .previews {
    min-height: 2190px;
    background-image: url(../images/previews-mobile.png);
  }
}

.container {
  margin: 4%;
  text-align: left;
}

.policy h3 {
  margin-top: 40px;
  margin-bottom: 1%;
}
.policy p {
  font-size: 0.9em;
  font-family: sans-serif;
}

.watch {
  cursor: pointer;
  background-color: #FFF;
  border-radius: 40px;
  opacity: .9;
  bottom: 3%;

}

.watch-wrapper {
  padding: 10px 20px;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  color: black;
}

.watch-icon {
  margin-right: 10px;
  width: 32px;
}